.footer {
  margin: 2em auto;
  max-width: calc(980px - 2em);
  width: calc(100% - 2em);
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;
  gap: 1.5rem;
}

.language {
  margin: 2em auto 3em;
  justify-content: center;
  display: flex;
  gap: 1em
}

.logo {
  flex: 0 0 40px;
}

.logo a {
  display: block;
}

.logo img {
  height: 50px;
}

.nav {
  font-size: .8em;
  flex: 1;
  color: rgba(255, 255, 255, .8);
}

.nav nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.nav a {
  margin-right: 1em;
  white-space: nowrap;
}

.social {
  margin: .5em 0;
  flex: 0 1 200px;
}

.social ul {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social li {
  list-style-type: none;
  padding: 0;
}

.social li img {
  width: 24px;
  height: 24px;
}

@media (max-width: 680px) {
  .footer {
    flex-direction: column;
    gap: .75rem;
  }

  .social {
    margin: 0;
    flex: 0 1 0;
  }

  .social ul {
    gap: 1.5rem;
    padding: 0;
  }
}