.exploreembed iframe {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: .5em;
}

.wrapper {
  width: 420px;
  height: calc(100vh - 125px);
  max-height: 960px;
  border-radius: .5em;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,.2);;
}

.exploreembed.loading {
  background-image: url('/images/loader.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.exploreembed.loading iframe {
  visibility: hidden;
}

.split {
  display: flex;
  gap: 2em;
  justify-content: space-evenly;
}

.split > div {
  max-width: calc(50% - 4em);
}

.explore ul {
  margin: 0;
  padding: 0;
  max-width: 360px;
  min-width: 220px;
  flex: 1 1;
}

.explore li {
  list-style-type: none;
  padding-bottom: .5em;
}

.explore li > div {
  display: flex;
}
.explore li h4 {
  margin: .7em 0 1em .8em;
  line-height: 1.2em;
  font-size: 1.2em;
  flex: 1;
}
.explore li p {
  margin: 0 0 1em calc(48px + 1em);
  line-height: 1.5em;
}

.explorebutton, .header {
  display: none;
}

.explore h3 {
  font-weight: 200;
  text-align: center;
  font-size: 2.5em;
  margin: 1.5em 0 1.5em;
}

.explore h3:after {
  content: "";
  display: block;
  margin: 25px auto;
  border-top: 2px solid #e3e3e3;
  width: 180px;
}


@media (max-width: 900px) {
  .explorebutton {
    display: block;
    margin-top: 1em;
  }

  .explore h3 {
    font-size: 1.8em;
  }

  .split > div {
    max-width: calc(100% - 2em);
  }

  .split > .exploreembed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    max-width: unset;
    transform: translateY(100vh);
    transition: transform .2s ease-out;
  }

  .header {
    position: relative;
    z-index: 9;
    background: rgba(0,0,0,0);
    display: block;
    width: 100vw;
    height: calc(65px + 1em);
    margin-bottom: -1em;
    transition: all .5s .1s ease-out;
  }

  .header:after {
    content: "";
    position: absolute;
    width: 100px;
    border-radius: 2px;
    height: 4px;
    bottom: calc(5px + 1em);
    left: 50%;
    margin: 0 -50px;
    background: rgba(255,255,255,.5);
  }

  .visible .header {
    background: rgba(0,0,0,.4);
    color: rgba(56,59,67,.94)
  }

  .exploreembed iframe {
    border-radius: 0;
    width: calc(100vw + 1px);
  }

  .wrapper {
    position: relative;
    z-index: 10;
    width: 100vw;
    height: calc(100% - 65px);
    border-radius: 1em 1em 0 0;
  }

  .visible .exploreembed {
    transform: translateY(0vh);
  }
}
