
.cards {
  position: relative;
  background: #f4f4f4;
  color: #111;
  overflow: hidden;
}

@media (min-width: 1000px) {
  .cards {
    padding-bottom: 4em;
  }
}

.cards section {
  margin: 0 auto;
  padding: 1em;
  box-sizing: border-box;
  max-width: 980px;
  box-shadow: 0 0 100px rgba(0,0,0,.1);
}

.cards section > div {
  margin: -.5em -1em;
  overflow: hidden;
  overflow-x: auto;
}


@keyframes fade {
  0%, 33.3% {
    opacity: 0;
    transform: translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cards h3 {
  margin: .35em .3em 1.2em;
}

.cards ul {
  margin: 0;
  padding: 0 1em;
  height: 164px;
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;  
}

.cards li {
  width: 280px;
  margin: 2px .5em;
  list-style-type: none;
  animation: fade .6s 0s ease-out 1;
  opacity: 1;
  transform: translateY(0);
}

.cards li:first-child {
  animation: fade .6s -.15s ease-out 1;
}

.cards li:first-child + li {
  animation: fade .6s -.1s ease-out 1;
}

.cards li:first-child + li + li {
  animation: fade .6s -.05s ease-out 1;
}

.cards li > a {
  position: relative;
  overflow: hidden;
  background: #fff;
  display: block;
  box-shadow: 0 7px 10px rgba(0,0,0,.1);
  height: calc(100% - 2em);
  padding: .3em .8em;
  margin-bottom: -.3em;
  text-decoration: none;
}

.cards li > a:hover {
  background: #fff;
  box-shadow: 0 7px 10px rgba(0,0,0,.2);
}

.cards li > a:focus {
  outline: none;
  box-shadow: 0 0 0 2px #aa8941, 0 7px 10px rgba(0,0,0,.2);
}

.card:after {
  content: '';
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  top: 0;
  width: 160px;
  background-image: linear-gradient(99deg, rgba(255,255,255,1) 13%, rgba(255,255,255,0) 120%);
}

.card .category {
  position: relative;
  z-index: 3;
  font-size: .825em;
  margin: .5em 0 -.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: AltoPro, sans-serif;
  color: #bca36c;
  font-size: 1em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.card h4 {
  position: relative;
  z-index: 3;
  color: #222;
  max-width: 150px;
}

.card img {
  position: absolute;
  z-index: 1;
  right: -40px;
  bottom: 0;
  top: 0;
  width: 200px;
  height: 100%;
  object-fit: cover;
  filter: grayscale() sepia() brightness(.75) saturate(1.5);
}